<template>
  <div class="container-fluid story position-relative py-5" id="weddingStory">
    <div class="container position-relative py-5">
      <div class="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
        <h5 class="text-uppercase text-primary fw-bold mb-4">História</h5>
        <h1 class="display-4">História de um nascimento de amor</h1>
      </div>
      <div class="story-timeline">
        <div class="row wow fadeInUp" data-wow-delay="0.2s">
          <div
            class="col-md-6 text-end border-0 border-top border-end border-secondary p-4"
          >
            <div class="d-inline-flex align-items-center h-100">
              <img
                src="../assets/img/story-1.jpg"
                class="img-fluid w-100 img-border"
                alt=""
              />
            </div>
          </div>
          <div
            class="col-md-6 border-start border-top border-secondary p-4 pe-0"
          >
            <div
              class="h-100 d-flex flex-column justify-content-center bg-secondary p-4"
            >
              <h4 class="mb-2 text-dark">A Descoberta</h4>
              <p
                class="text-uppercase text-primary mb-2"
                style="letter-spacing: 3px"
              >
                00 Jan 2050
              </p>
              <p class="m-0 fs-5">
                No instante em que soubemos que uma nova vida estava a caminho,
                o coração se encheu de alegria e expectativas. A surpresa de
                descobrir que um serzinho estava crescendo trouxe consigo a
                promessa de um futuro repleto de amor.
              </p>
            </div>
          </div>
        </div>
        <div
          class="row flex-column-reverse flex-md-row wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <div
            class="col-md-6 text-end border-end border-top border-secondary p-4 ps-0"
          >
            <div
              class="h-100 d-flex flex-column justify-content-center bg-secondary p-4"
            >
              <h4 class="text-dark mb-2">Os Primeiros Movimentos</h4>
              <p
                class="text-uppercase text-primary mb-2"
                style="letter-spacing: 3px"
              >
                00 Jan 2050
              </p>
              <p class="m-0 fs-5">
                Conforme os meses passavam, sentíamos as primeiras batidas e
                movimentos, como pequenos sinais de vida se manifestando. Cada
                ultrassom, cada nova descoberta sobre o bebê, fazia com que o
                amor florescesse e crescesse ainda mais dentro de nós.
              </p>
            </div>
          </div>
          <div class="col-md-6 border-start border-top border-secondary p-4">
            <div class="d-inline-flex align-items-center h-100">
              <img
                src="../assets/img/story-2.jpg"
                class="img-fluid w-100 img-border"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp" data-wow-delay="0.4s">
          <div
            class="col-md-6 text-end border-end border-top border-secondary p-4 ps-0"
          >
            <div class="d-inline-flex align-items-center h-100">
              <img
                src="../assets/img/story-3.jpg"
                class="img-fluid w-100 img-border"
                alt=""
              />
            </div>
          </div>
          <div
            class="col-md-6 border-start border-top border-secondary p-4 pe-0"
          >
            <div
              class="h-100 d-flex flex-column justify-content-center bg-secondary p-4"
            >
              <h4 class="text-dark mb-2">A Conexão Cresce</h4>
              <p
                class="text-uppercase text-primary mb-2"
                style="letter-spacing: 3px"
              >
                00 Jan 2050
              </p>
              <p class="m-0 fs-5">
                Ao longo da jornada, cada detalhe, desde preparar o quarto até
                escolher o nome, trouxe à tona a profundidade de um amor
                incondicional. A conexão entre nós e o bebê se fortaleceu, e o
                desejo de conhecê-lo pessoalmente se tornou mais intenso.
              </p>
            </div>
          </div>
        </div>
        <div
          class="row flex-column-reverse flex-md-row wow fadeInUp"
          data-wow-delay="0.5s"
        >
          <div
            class="col-md-6 text-end border border-start-0 border-secondary p-4 ps-0"
          >
            <div
              class="h-100 d-flex flex-column justify-content-center bg-secondary p-4"
            >
              <h4 class="text-dark mb-2">O Nascimento</h4>
              <p
                class="text-uppercase text-primary mb-2"
                style="letter-spacing: 3px"
              >
                00 Jan 2050
              </p>
              <p class="m-0 fs-5">
                O grande dia chegou, e com ele, o encontro mais esperado de
                nossas vidas. Quando finalmente seguramos o bebê nos braços,
                soubemos que toda a jornada valeu a pena. O amor, que já era
                imenso, se multiplicou de uma forma que nunca imaginamos ser
                possível.
              </p>
            </div>
          </div>
          <div class="col-md-6 border border-end-0 border-secondary p-4">
            <div class="d-inline-flex align-items-center h-100">
              <img
                src="../assets/img/story-4.jpg"
                class="img-fluid w-100 img-border"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>