<template>
  <FormCompo/>
</template>

<script>
import FormCompo from '@/components/FormCompo.vue';
export default {
components:{
    FormCompo
}
}
</script>

<style>

</style>