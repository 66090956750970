<template>
  <CaroselCompo/>
</template>

<script>
import CaroselCompo from "@/components/CaroselCompo.vue";
export default {
  name:'IndexView',
  components: {
    CaroselCompo,
  },
};
</script>

<style>
</style>