<template>
  <router-view />
</template>

<script>
export default {

}
</script>

<style>
#app {
  text-align: center;
  color: #2c3e50;
}
#navbarCollapse > div > a {
  margin: 0 10px;
}
#app > nav > div {
  margin: 0;
}
#navBar {
  padding: 0%;
}
</style>